<template>
  <confirm-dialog
    ref="dialog"
    v-model="bookmarkDialog"
    icon="mdi-bookmark"
    title="バインダー"
    confirm-label="バインダーに登録する"
    message="この内容でバインダーに登録します"
    :disabled="selectedBinderToBookmark === null || descriptionValidator(bookmarkDescription) !== true"
    @confirm="bookmarkCommitHandler"
  >
    <v-select
      v-model="selectedBinderToBookmark"
      :items="binders"
      label="登録先バインダーを選択してください"
      item-color="primary"
      item-text="name"
      item-value="id"
    />
    <div class="webview-dialog-body">
      <p>
        文献名 {{ record.title }}<br />
        {{ headingText }}
      </p>
    </div>
    <v-textarea v-model="bookmarkDescription" label="メモ" outlined :rules="[descriptionValidator]" />
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { descriptionValidator } from '@/utils/binderFoldersUtils';
import { Binder, Bookmark, DocRecord } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';

@Component({
  components: { ConfirmDialog },
})
export default class AddPageBookmarkDialog extends Vue {
  $refs!: {
    dialog: ConfirmDialog;
  };

  descriptionValidator = descriptionValidator;

  @Prop()
  record!: DocRecord;

  @Prop()
  success?: (response: Bookmark) => void;

  selectedPageSeq: number | null = null;
  get bookmarkDialog(): boolean {
    return this.selectedPageSeq !== null;
  }
  set bookmarkDialog(value) {
    if (!value) {
      this.selectedPageSeq = null;
    } else {
      console.warn('set selectedPageSeq to activate bookmarkDialog');
    }
  }

  get headingText(): string {
    if (this.selectedPageSeq !== null) {
      if (isAccessible(this.record) && this.record.folioPerSeq && this.record.folioPerSeq[this.selectedPageSeq]) {
        return `${this.record.folioPerSeq[this.selectedPageSeq]} ページ`;
      } else {
        return `${this.selectedPageSeq + 1} ページ`;
      }
    }

    return '';
  }

  binders: Binder[] = [];
  selectedBinderToBookmark: string | null = null;
  bookmarkDescription = '';

  get selectedBinder(): Binder | null {
    if (this.selectedBinderToBookmark === null) {
      return null;
    }
    return this.binders.find((b) => b.id === this.selectedBinderToBookmark) || null;
  }

  async bookmarkCommitHandler() {
    if (this.selectedPageSeq === null || this.selectedBinder === null) {
      this.bookmarkDialog = false;
      return;
    }

    try {
      const docDataUpdatedAt =
        this.record.publishedOn == null ? null : new Date(this.record.publishedOn).getTime() / 1000;
      const response = await this.$repositories.binderFolders.addPageBookmarkToBinder(
        this.selectedBinder.id,
        this.record.id,
        this.bookmarkDescription,
        docDataUpdatedAt,
        this.selectedPageSeq,
        this.headingText,
      );
      this.$toast.success('バインダーに登録しました');
      this.bookmarkDialog = false;
      if (this.success) {
        this.success(response);
      }
    } catch (error) {
      this.$refs.dialog.reactivate();
      console.error(error);
      this.$toast.error('バインダーへの登録に失敗しました');
    }
  }

  async show(pageSeq: number) {
    const promise = this.$repositories.binderFolders.getAllBinders(false).then((binders) => {
      this.binders = binders;
    });

    this.selectedPageSeq = pageSeq;
    this.selectedBinderToBookmark = null;
    this.bookmarkDescription = '';
    await promise;
  }
}
</script>
